import { Grid } from '@mui/material';
import { Card, CardHeader } from "../../../_components/card/";
import React, { useEffect,useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '..'
import { validate } from './validate';
import { handleInputChange,validateForm } from '../../../_helpers';
import { FormInput, FormPhoneInput } from '../../form';


function UpdateBeneficiaryModal({ show, showLoader, closeModal, updateBeneficiary, beneficiaryData }) {
    const [updateData, setUpdateData] = useState({ email: '', phone: '',address: '',address1: '',city: '',state: '',country_code: '', zip_code: ''});
    const [action,setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [errors, setErrors] = useState({ ...updateData });

    useEffect(() => {

        if (beneficiaryData) {
            setUpdateData({
                email: beneficiaryData.email,
                phone: beneficiaryData.phone,
                address: beneficiaryData.address,
                address1: beneficiaryData.address1,
                city: beneficiaryData.city,
                state: beneficiaryData.state,
                country_code: beneficiaryData.country_code,
                zip_code: beneficiaryData.zip_code
            });
        } 
    }, [beneficiaryData])

    /**
     * function to handle input changes and modify the value
     * @author Drishya
     * @created_at 22 May 2023
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, updateData)) });
        updateData[name] = value;
        // if(name === 'phone'){
        //     let val = e.target.value;
        //     val = val.replace(/ /gm, '');
        //     console.log(val);

        //     let num = `${(val.substring(0, 3))} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;
        //     // let num2 = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;
        //     // let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

        //     num = num.trim();

        //     updateData[name] = num;
        // }
        setUpdateData({ ...updateData });
    }

    // const onClose = () => {
    //     // setUpdateData({ email: '', phone: '',address: '',address1: '',city: '',state: '',country_code: '', zip_code: '' });
    //     closeModal(false);
        
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });
		const formErrors = {};
		for (const [name, value] of Object.entries(updateData)) {
			const inputError = validate(name, value);
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			setErrors(formErrors);
			setAction({ isSubmitted: true });
			return;
		}
        updateBeneficiary({ email: updateData.email, phone: updateData.phone, address: updateData.address, address1: updateData.address1, city: updateData.city, state: updateData.state, country_code: updateData.country_code, zip_code: updateData.zip_code});
        setUpdateData({ email: '', phone: '',address: '',address1: '',city: '',state: '',country_code: '', zip_code: ''  });
    }

    return (
        <Modal isDialogOpen={show} onClose={closeModal} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
            <CardHeader title='Update Beneficiary Details' />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <Grid container spacing={4}>
                    
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormInput
                                tabIndex={1}
                                label='Email'
                                name='email'
                                value={updateData.email}
                                error={action.isSubmitted && errors.email ? errors.email : ''}
                                onChange={handleChange}
                                autoFocus={true}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormPhoneInput
                                tabIndex={13}
                                label='Phone Number'
                                name='phone'
                                value={updateData.phone}
                                error={action.isSubmitted && errors.phone ? errors.phone : ''}
                                onChange={handleChange}
                                maskType={`digit-only`}
							    maxLength={13}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormInput
                                tabIndex={1}
                                label='Address Line 1'
                                name='address'
                                value={updateData.address}
                                error={action.isSubmitted && errors.address ? errors.address : ''}
                                onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormInput
                                tabIndex={1}
                                label='Address Line 2'
                                name='address1'
                                value={updateData.address1}
                                error={action.isSubmitted && errors.address1 ? errors.address1 : ''}
                                onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormInput
                                tabIndex={1}
                                label='City'
                                name='city'
                                value={updateData.city}
                                error={action.isSubmitted && errors.city ? errors.city : ''}
                                onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormInput
                                tabIndex={1}
                                label='State'
                                name='state'
                                value={updateData.state}
                                error={action.isSubmitted && errors.state ? errors.state : ''}
                                onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormInput
                                tabIndex={1}
                                label='Country Code'
                                name='country_code'
                                value={updateData.country_code}
                                error={action.isSubmitted && errors.country_code ? errors.country_code : ''}
                                onChange={handleChange}
                                readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <FormInput
                                tabIndex={1}
                                label='Zip Code'
                                name='zip_code'
                                value={updateData.zip_code}
                                error={action.isSubmitted && errors.zip_code ? errors.zip_code : ''}
                                onChange={handleChange}
                                maskType={`digit-only`}
                                maxLength={9}
                                />
                            </Grid>
                        </Grid>
                    </ModalBody>
                    <ModalFooter style={{ padding: 0 }}>
                        <ModalFooterSaveBtn tabIndex={3} text={`OK`} isProcessing={action.isProcessing} />
                        <ModalFooterCloseBtn tabIndex={4} onClose={closeModal} />
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { UpdateBeneficiaryModal }